import { OnInit, OnDestroy, Component,Renderer2  } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreConfigService } from '@core/services/config.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {
  public coreConfig: any;
  public year: number = new Date().getFullYear();
  mycall_html='';
  // Private
  private _unsubscribeAll: Subject<any>;


  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(public _coreConfigService: CoreConfigService,private modalService: NgbModal,private renderer: Renderer2) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }



  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      if (this._coreConfigService.code_user!='' && this._coreConfigService.code_user!= undefined)
      {
        
        const script = this.renderer.createElement('script');
        this.renderer.setAttribute(script, 'src', 'script_files/'+this._coreConfigService.code_user+'.js');
       this.renderer.appendChild(document.body, script);
      }
    });

  
    //this.mycall_html =localStorage.getItem('for_cleint');
    
    if (this.mycall_html!="")
    {
      //this.loadScript(this.mycall_html);
    }
  }

  public loadScript( scr_url) {
    console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = scr_url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
}

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  



}
