import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import * as io from "socket.io.min.js"
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';

const peerConnectionConfig = {
  configuration: {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true
  },
  iceServers: []
};

@Injectable({
  providedIn: 'root',
})
export class ChatService {



  public chats: any[];
  public userProfile;
  public isChatOpen: Boolean;
  public chatUsers: any[];
  public selectedChat;
  public selectedChatUser;
  user_id;
  user_role;
  public onChatsChange: BehaviorSubject<any>;
  public onSelectedChatChange: BehaviorSubject<any>;
  public onSelectedChatUserChange: BehaviorSubject<any>;
  public onChatUsersChange: BehaviorSubject<any>;
  public onChatOpenChange: BehaviorSubject<Boolean>;
  public onUserProfileChange: BehaviorSubject<any>;
  public onLocalStreamChange: BehaviorSubject<any>;
  public onRemoteStreamChange: BehaviorSubject<any>;

  public socket = null;
  public getCalled = false;
  local_stream: any;



  constructor(private _httpClient: HttpClient, private _authenticationService: AuthenticationService, private _toastrService: ToastrService) {
    this.isChatOpen = false;

    this.onChatsChange = new BehaviorSubject([]);
    this.onSelectedChatChange = new BehaviorSubject([]);
    this.onSelectedChatUserChange = new BehaviorSubject([]);
    this.onChatUsersChange = new BehaviorSubject([]);
    this.onChatOpenChange = new BehaviorSubject(false);
    this.onUserProfileChange = new BehaviorSubject([]);
    this.onLocalStreamChange = new BehaviorSubject([]);
    this.onRemoteStreamChange = new BehaviorSubject([]);

    // this.socket = io.connect('https://localhost');
    this.socket = io.connect('https://panel.ecoline.fi');


    this._authenticationService.currentUser.subscribe(x => {
      if (x) {
        this.user_id = x.id;
        this.user_role = x.role;
      }
      else {
        this.user_id = '';
        this.user_role = '';
      }
    });
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  //resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
  stat_service(): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        //  this.getChats(),
        this.getUserProfile(),
        this.getActiveChats(),
        //this.getActiveUsers(),
        //this.getChatUsers(),
        this.StartIo()
      ]).then(() => {
        resolve();
      }, reject);
    });
  }

  StartIo(): any {

    this.socket.on("open-chat", (data) => {
      console.log("open-chat 2");
      if (!this.chatUsers) {
        if (data.socket_id == this.socket.id) {
          this.socket.emit("get-chat-active-user", {
            socket_id: this.socket.id,
            user_id: this.user_id,
            user_role: this.user_role,
            record_status: true
          });
        }
      }
    });

    this.socket.on("set-chat-active-user", (data) => {
      console.log("set-chat-active-user 2");
      if (!this.chatUsers) {
        if (data.socket_id == this.socket.id) {
          this.chatUsers = [];
          data.data.forEach(element => {
            this.chatUsers.push(element.data);
          });

          this.onChatUsersChange.next(this.chatUsers);
        }
      }
    });

    this.socket.on("chat-online", (data) => {
      console.log("chat-online 2");
      if (!this.chatUsers)
        this.chatUsers = [];

      const date_time = new Date();
      const user_u = {
        id: data.sender_socket_id,
        fullName: data.fullName,
        role: data.role,
        about: data.about,
        avatar: data.avatar,
        status: 'online',
        loginTime: date_time.toLocaleTimeString(),
        mode: data.mode,
        mycall_level_1: data.mycall_level_1,
        mycall_level_2: data.mycall_level_2,
        code_user: data.code_user,
        level_1: data.level_1,
        level_2: data.level_2,
        Username: data.Username,
        calling_mode: '',
        email: data.email,
        Flag_pause: false
      }

      if (data.user_level == this.user_id || this.user_role == 'Admin') {
        const selectUser = this.chatUsers.find(x => x.id === data.sender_socket_id);
        if (selectUser == undefined) {
          this.chatUsers.push(user_u);
          this.onChatUsersChange.next(this.chatUsers);

        }
        else {
          if (this.chatUsers) {
            let selectUser = this.chatUsers.find(x => x.id === data.sender_socket_id);
            selectUser.status = 'online';
            selectUser.mycall_level_1 = data.mycall_level_1;
            selectUser.mycall_level_2 = data.mycall_level_2;
            selectUser.code_user = data.code_user;
            this.onChatUsersChange.next(this.chatUsers);

          }
        }
      }

    });

    this.socket.on("chat-recive-message", (data) => {
      console.log("chat-recive-message 2");
      if (this.chatUsers) {
        let selectUser = this.chatUsers.find(x => x.id === data.sender_socket_id);
        if (selectUser) {
          this.updateMsgChat(data);
        }
      }
    });

    this.socket.on("remove-user", ({ socketId }) => {
      console.log("remove-user 2");
      if (this.chatUsers) {
        let selectUser = this.chatUsers.find(x => x.id === socketId);
        if (selectUser) {
          this.chatUsers = this.chatUsers.filter(x => x.id != socketId);
          this.onChatUsersChange.next(this.chatUsers);
        }
      }
    });

    this.socket.on("chat-offline", (data) => {
      console.log("chat-offline 2");
      if (this.chatUsers) {
        let selectUser = this.chatUsers.find(x => x.id === data.sender_socket_id);
        if (selectUser) {
          selectUser.calling_mode = '';
          selectUser.status = 'offline';
          this.onChatUsersChange.next(this.chatUsers);
        }
      }
    });

    this.socket.on("get-offer", data => {
      console.log("get-offer 2");
      if (data.reciver_socket_id == this.socket.id) {
        if (this.chatUsers) {
          let selectUser = this.chatUsers.find(x => x.id === data.client_id);
          if (selectUser) {
            this.set_offer(selectUser, data)
          }
        }
      }
    });

    this.socket.on("call-made", data => {
      console.log("call-made 2");
      if (data.reciver_socket_id == 'Admin') {
        if (this.chatUsers) {
          let selectUser = this.chatUsers.find(x => x.id === data.sender_socket_id);
          if (selectUser) {
            selectUser.status = 'online';
            selectUser.mode = data.mode;
            selectUser.calling_mode = 'calling';
            this.onChatUsersChange.next(this.chatUsers);

            this._toastrService.error(
              'calling ring recive', selectUser.fullName,
              { toastClass: 'toast ngx-toastr', closeButton: true }
            );

          }

        }
      }
    });

    this.socket.on("call-rejected", data => {
      console.log("call-rejected 2");
      if (this.chatUsers) {
        let selectUser = this.chatUsers.find(x => x.id === data.sender_socket_id);
        if (selectUser) {
          // console.log("call-rejected");
          //if (this.selectedChatUser)
          // if (this.selectedChatUser.id==selectUser.id)
          //     this.peerConnection.close();            
          selectUser.calling_mode = '';
          selectUser.mode = 'text';

          if (selectUser.peerConnection) {
            if (selectUser.peerConnection.connectionState != 'closed') {
              const senders = selectUser.peerConnection.getSenders();
              senders.forEach((sender) => selectUser.peerConnection.removeTrack(sender));
              this.local_stream.getTracks().forEach((track) => {
                if (track.readyState == 'live') {
                  track.stop();
                }
              });
              selectUser.peerConnection.close();
            }
          }

          this.onChatUsersChange.next(this.chatUsers);
        }
      }
    });

    this.socket.on("candidate-made", data => {

      try {
        console.log("addIceCandidate 2");
        let selectUser = this.chatUsers.find(x => x.id === data.user_id);
        if (selectUser) {
          if (data.candidate)
            if (data.candidate.sdpMid)
              selectUser.peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate)).catch((e) => {
                console.log("addIceCandidate 2", e);
              });
        }
      } catch (error) {
        console.log(error);
      }

    });


    this.socket.on("pickup_call_done", data => {
      console.log("call-made 2");
      if (data.reciver_socket_id == 'Admin') {
        if (this.chatUsers) {
          let selectUser = this.chatUsers.find(x => x.id === data.id);
          if (selectUser) {
            this.pickup_call_done(selectUser);
          }

        }
      }
    });

  }




  async set_offer(selectUser, data) {

    await selectUser.peerConnection.setRemoteDescription(data.description);
  }

  updateMsgChat(data) {

    if (data.reciver_socket_id == 'Admin') {
      if (!this.chats)
        this.chats = [];

      var selectChat = this.chats.find(chat => chat.userId === data.sender_socket_id);
      const date_time = new Date();
      // If Chat is Avaiable of Selected Id
      if (selectChat !== undefined) {

        selectChat.chat.push(
          {
            message: data.msg,
            time: date_time,
            senderId: data.sender_socket_id
          }
        );
        if (this.selectedChatUser) {
          if (data.sender_socket_id != this.selectedChatUser.id)
            selectChat.unseenMsgs++;
          else
            selectChat.unseenMsgs = 0;
        }
        else
          selectChat.unseenMsgs++;
        const otherChat = this.chats.filter(chat => chat.userId != data.sender_socket_id);

        this.chats = [...otherChat, selectChat];

        if (this.selectedChatUser) {
          if (data.sender_socket_id === this.selectedChatUser.id)
            this.onSelectedChatChange.next(selectChat);
          else
            this.onChatsChange.next(this.chats);
        }
        else
          this.onChatsChange.next(this.chats);
      }
      // Else Create New Chat
      else {
        const newChat =
        {
          id: data.sender_socket_id,
          userId: data.sender_socket_id,
          unseenMsgs: 1,
          chat: [
            {
              message: data.msg,
              time: date_time,
              senderId: data.sender_socket_id
            }
          ]
        };

        const otherChat = this.chats.filter(chat => chat.userId != data.sender_socket_id);
        this.chats = [...otherChat, newChat];

        if (this.selectedChatUser) {
          if (data.sender_socket_id === this.selectedChatUser.id)
            this.onSelectedChatChange.next(newChat);
          else
            this.onChatsChange.next(this.chats);
        }
        else
          this.onChatsChange.next(this.chats);

      }

    }
  }


  /**
   * Get Chats
   */
  getChats(): Promise<any[]> {
    const url = `${environment.apiUrl}/api/chat-chats`;

    return new Promise((resolve, reject) => {
      this._httpClient.get(url).subscribe((response: any) => {

        this.chats = response;
        this.onChatsChange.next(this.chats);

        resolve(this.chats);
      }, reject);
    });
  }

  /**
   * Get User Profile
   */
  getUserProfile(): Promise<any[]> {
    const user_id = this.user_id;

    const params = {
      user_id: user_id
    }
    const url = `${environment.apiUrl}/api/chat-profileUser`;
    return new Promise((resolve, reject) => {
      this._httpClient.get(url, { params }).subscribe((response: any) => {

        this.userProfile = response;
        this.onUserProfileChange.next(this.userProfile);
        resolve(this.userProfile);
      }, reject);
    });
  }

  /**
   * Get Selected Chat User
   *
   * @param userId
   */
  getSelectedChatUser(userId) {

    if (this.chatUsers) {
      const selectUser = this.chatUsers.find(x => x.id === userId);
      if (selectUser) {
        this.selectedChatUser = selectUser;
        this.onSelectedChatUserChange.next(this.selectedChatUser);
      }
    }
  }

  /**
   * Get Active Chats
   */
  getActiveChats() {

    if (this.chats) {
      if (this.selectedChatUser) {
        const chatArr = this.chats.filter(chat => {
          return chat.userId === this.selectedChatUser.id;
        });
      }
      else
        return null;
    }
    else
      return null;
  }

  /**
   * Get Chat Users
   */
  getChatUsers() {
    const user_id = this.user_id;

    const params = {
      user_id: user_id
    }

    const url = `${environment.apiUrl}/api/chat-users`;

    return new Promise((resolve, reject) => {
      this._httpClient.get(url, { params }).subscribe((response: any) => {

        this.chatUsers = response;
        this.onChatUsersChange.next(this.chatUsers);

        resolve(this.chatUsers);
      }, reject);
    });


  }

  /**
   * Selected Chats
   *
   * @param id
   */
  selectedChats(id) {

    if (this.chats) {
      const selectChat = this.chats.find(chat => chat.userId === id);

      // If Chat is Avaiable of Selected Id
      if (selectChat !== undefined) {
        this.selectedChat = selectChat;

        this.onSelectedChatChange.next(this.selectedChat);
      }
      // Else Create New Chat
      else {
        const newChat = {
          userId: id,
          unseenMsgs: 0
        };
        this.onSelectedChatChange.next(newChat);
      }
    }

    this.getSelectedChatUser(id);

  }

  /**
   * Create New Chat
   *
   * @param id
   * @param chat
   */
  createNewChat(id, chat) {

    const newChat = {
      userId: id,
      unseenMsgs: 0,
      chat: [chat]
    };

    if (chat.message !== '') {
      return new Promise<void>((resolve, reject) => {
        this._httpClient.post(`${environment.apiUrl}/api/chat-chats/`, { ...newChat }).subscribe(() => {
          //  this.getChats();
          //this.getChatUsers();
          this.getSelectedChatUser(id);
          this.openChat(id);
          resolve();
        }, reject);
      });
    }
  }

  /**
   * Open Chat
   *
   * @param id
   */
  openChat(id) {
    if (this.selectedChatUser)
      if (this.selectedChatUser.id != id)
        this.call_pause_resume(true);
    this.isChatOpen = true;
    this.onChatOpenChange.next(this.isChatOpen);
    this.selectedChats(id);

    if (this.selectedChatUser.stream_c)
      this.onRemoteStreamChange.next({ event: this.selectedChatUser.stream_c, mode: this.selectedChatUser.mode });
  }

  /**
   * Update Chat
   *
   * @param chats
   */
  updateChat(msg) {

    this.socket.emit("chat-send-message", {
      sender_socket_id: this.socket.id,
      reciver_socket_id: this.selectedChatUser.id,
      msg: msg,
      userProfile: this.userProfile
    });

  }

  /**
   * Update User Profile
   *
   * @param userProfileRef
   */
  updateUserProfile(userProfileRef) {
    this.userProfile = userProfileRef;
    this.onUserProfileChange.next(this.userProfile);
  }


  reject_call() {
    if (this.selectedChatUser.peerConnection)

      if (this.selectedChatUser.peerConnection.connectionState != 'closed') {
        const senders = this.selectedChatUser.peerConnection.getSenders();
        senders.forEach((sender) => this.selectedChatUser.peerConnection.removeTrack(sender));
        this.local_stream.getTracks().forEach((track) => {
          if (track.readyState == 'live') {
            track.stop();
          }
        });
        this.selectedChatUser.peerConnection.close();
      }

    this.selectedChatUser.calling_mode = '';
    this.onChatUsersChange.next(this.chatUsers);

    this.socket.emit("reject-call", {
      sender_socket_id: this.socket.id,
      reciver_socket_id: this.selectedChatUser.id,
      user_id: this.selectedChatUser.id
    });
  }

  record_status_changed(flag_record) {

    if (this.selectedChatUser)
      this.socket.emit("record_status_changed", {
        sender_socket_id: this.socket.id,
        reciver_socket_id: this.selectedChatUser.id,
        flag_record: flag_record,
        user_id: this.selectedChatUser.id

      });
  }

  call_pause_resume(flag_status) {
    console.log("call_pause_resume");
    if (this.selectedChatUser) {
      this.selectedChatUser.Flag_pause = flag_status;
      if (this.selectedChatUser.peerConnection) {
        let senderList = this.selectedChatUser.peerConnection.getSenders();
        senderList.forEach((sender) => {
          sender.track.enabled = !flag_status;
        });

        this.socket.emit("call_pause_resume", {
          sender_socket_id: this.socket.id,
          reciver_socket_id: this.selectedChatUser.id,
          flag_status: flag_status
        });
      }
    }

  }

  pickup_call() {
    if (this.selectedChatUser) {
      // if (!this.selectedChatUser.peerConnection)
      // {
      //       this.socket.emit("pickup_call", {
      //         sender_socket_id: 'Admin',
      //         reciver_socket_id: this.selectedChatUser.id,
      //         user_id:this.selectedChatUser.id
      //         });
      // }
      this.pickup_call_done(this.selectedChatUser);
    }

  }

  pickup_call_done(selectUser_pickup: any) {
    if (this.local_stream) {

      selectUser_pickup.peerConnection = new RTCPeerConnection();

      selectUser_pickup.peerConnection.onicecandidate = event => {
        console.log("onicecandidate 2");
        if (event.candidate) {
          if (selectUser_pickup) {
            this.socket.emit("candidate-make", {
              candidate: event.candidate,
              sender_socket_id: 'Admin',
              reciver_socket_id: selectUser_pickup.id,
              user_id: selectUser_pickup.id
            });
          }
        }
      };

      selectUser_pickup.peerConnection.onnegotiationneeded = async () => {
        console.log("onnegotiationneeded 2");
        try {
          if (selectUser_pickup) {
            await selectUser_pickup.peerConnection.setLocalDescription(await selectUser_pickup.peerConnection.createOffer());

            this.socket.emit("send-offer", {
              description: selectUser_pickup.peerConnection.localDescription,
              sender_socket_id: 'Admin',
              reciver_socket_id: selectUser_pickup.id,
              data: selectUser_pickup,
              userProfile: this.userProfile,
              user_id: selectUser_pickup.id
            });
          }
        } catch (err) {
          console.error(err);
        }
      }

      selectUser_pickup.peerConnection.oniceconnectionstatechange = () => {
        console.log("oniceconnectionstatechange 2");
        if (selectUser_pickup.peerConnection.iceConnectionState === "failed") {
          selectUser_pickup.peerConnection.restartIce();
        }
      };

      selectUser_pickup.peerConnection.ontrack = event => {
        {
          console.log("ontrack 2");
          selectUser_pickup.stream_c = event.streams[0];
          this.onRemoteStreamChange.next({ event: event.streams[0], mode: selectUser_pickup.mode });
        }
      };

      this.local_stream.getTracks().forEach(track => {
        selectUser_pickup.peerConnection.addTrack(track, this.local_stream);
      });


      this.onLocalStreamChange.next(selectUser_pickup);

      selectUser_pickup.calling_mode = 'called';
      this.onChatUsersChange.next(this.chatUsers);
    }
    else {
      alert("erroe on media");
    }
  }

  setLocalStream(stream: any) {
    this.local_stream = stream;
  }


  public upload(formData) {

    return this._httpClient.post<any>(`${environment.apiUrl}/api/uploadfile/`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }



}
