<!-- card snippet component -->
<div class="card">
  <div class="card-header">
    <ng-content select=".card-title"></ng-content>

  
  </div>

  <!--? content wrapped with .card-body -->
  <ng-content select=".card-body"></ng-content>

  <!--? content without any wrapper -->
  <ng-content></ng-content>


</div>
<!-- /card snippet component -->
