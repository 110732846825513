import { CoreMenu } from '@core/types';


export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'item',
    role: ['Admin', 'Client','User'],
    icon: 'home',
    url: 'dashboard'
  },
  {
    id: 'profile',
    title: 'Profile',
    translate: 'MENU.PAGES.PROFILE',
    type: 'item',
    icon: 'user',
    role: ['Admin', 'Client','User'],
    url: 'pages/account-settings'
    // collapsed: true
  },
  // {
  //   id: 'Services',
  //   title: 'Services',
  //   translate: 'MENU.UI.Services',
  //   type: 'item',
  //   icon: 'credit-card',
  //   role: ['Admin', 'Client','User'],
  //   url: 'pages/services'
  // },
  {
    id: 'users',
    title: 'User and Level',
    role: ['Admin', 'Client'],
    translate: 'MENU.APPS.USER.COLLAPSIBLE',
    type: 'collapsible',
    icon: 'users',
    children: [
      {
        id: 'users',
        title: 'Users',
        role: ['Admin', 'Client'],
        translate: 'MENU.APPS.USER.LIST',
        type: 'item',
        icon: 'circle',
        url: 'pages/user-list'
      },
      {
        id: 'level',
        role: ['Admin', 'Client'],
        title: 'Levels',
        translate: 'MENU.APPS.USER.LEVEL',
        type: 'item',
        icon: 'circle',
        url: 'pages/level-list'
      }
      
    ]
  },
  {
    id: 'Script',
    title: 'Script',
    role: ['Admin', 'Client'],
    translate: 'MENU.APPS.Script',
    type: 'item',
    icon: 'check-square',
    url: 'pages/script'
  },
  {
    id: 'Reports',
    title: 'Reports',
    role: ['Admin', 'Client'],
    translate: 'MENU.PAGES.Reports',
    type: 'collapsible',
    icon: 'layout',
    children: [
      {
        id: 'Reports1',
        title: 'Report Text Chat',
        icon: 'circle',
        type: 'item',
        url: 'tables/datatable1'
      },
      {
        id: 'Reports2',
        title: 'Report Voice Chat',
        icon: 'circle',
        type: 'item',
        url: 'tables/datatable2'
      },
      {
        id: 'Reports3',
        title: 'Report Video Chat',
        icon: 'circle',
        type: 'item',
        url: 'tables/datatable3'
      }
    ]
  },
  {
    id: 'softphone',
    title: 'SoftPhone',
    translate: 'MENU.APPS.CHAT',
    type: 'item',
    icon: 'message-square',
    url: 'pages/chat'
    // collapsed: true
  }
];
